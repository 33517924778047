require('./bootstrap');
import './utils/PageParams';
import Alpine from 'alpinejs';
import initMultiSelect from './components/multiSelect';
import { getInitialRules, validatePasswordRules } from './password-rules';
import { resetPassword, createPassword, changePassword } from './password-validation';
import userAdministration from './user-administration';
window.Alpine = Alpine;
window.multiSelect = initMultiSelect;
window.userAdministration = userAdministration;
window.resetPassword = resetPassword;
window.createPassword = createPassword;
window.getInitialRules = getInitialRules;
window.validatePasswordRules = validatePasswordRules;
window.changePassword = changePassword;
Alpine.start();
