export const passwordRules = [
    {
        test: pwd => pwd.length >= 12,
        message: 'At least 12 characters long',
        valid: false,
    },
    {
        test: pwd => /[A-Z]/.test(pwd),
        message: 'Contains uppercase letter',
        valid: false,
    },
    {
        test: pwd => /[a-z]/.test(pwd),
        message: 'Contains lowercase letter',
        valid: false,
    },
    {
        test: pwd => /[0-9]/.test(pwd),
        message: 'Contains number',
        valid: false,
    },
    {
        test: pwd => /[^A-Za-z0-9]/.test(pwd),
        message: 'Contains special character',
        valid: false,
    }
];

export function getInitialRules() {
    return passwordRules.map(rule => ({...rule, valid: false}));
}

export function validatePasswordRules(password) {
    return passwordRules.map(rule => ({
        ...rule,
        valid: rule.test(password)
    }));
}
