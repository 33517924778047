function createPasswordValidation(options = { requireConfirmation: false }) {
    return {
        password: '',
        isFormValid: false,
        password_confirmation: options.requireConfirmation ? '' : null,
        passwordConfirmationError: false,
        passwordRules: getInitialRules(),
        formValid: false,

        updateFormValid() {
            const passwordValid = this.passwordRules.every(rule => rule.valid) &&
                this.password.length > 0;

            if (!options.requireConfirmation) {
                this.formValid = passwordValid;
            } else {
                this.formValid = passwordValid &&
                    !this.passwordConfirmationError &&
                    this.password === this.password_confirmation;
            }
            console.log('Form valid:', this.formValid);
        },

        validatePassword() {
            this.passwordRules = validatePasswordRules(this.password);

            this.updateFormValid();

            const requirementsRegion = document.getElementById('password-requirements');
            if (requirementsRegion) {
                requirementsRegion.setAttribute('aria-label',
                    `Password requirements - ${this.passwordRules.filter(r => r.valid).length} of ${this.passwordRules.length} met`
                );
            }

            if (options.requireConfirmation && this.password_confirmation) {
                this.validatePasswordConfirmation();
            }
        },

        validatePasswordConfirmation() {
            if (!options.requireConfirmation) return;
            this.passwordConfirmationError = this.password !== this.password_confirmation;
            this.updateFormValid();

            const matchStatus = document.getElementById('password-match-status');
            if (matchStatus) {
                matchStatus.setAttribute('aria-label',
                    this.passwordConfirmationError ? 'Passwords do not match' : 'Passwords match'
                );
            }
        },

        validateForm() {
            this.validatePassword();
            if (options.requireConfirmation) {
                this.validatePasswordConfirmation();
            }
            if (this.formValid) {
                this.$el.submit();
            } else {
                const firstError = document.querySelector('[role="alert"]');
                if (firstError) {
                    firstError.focus();
                }
            }
        }
    };
}

export function resetPassword() {
    return {
        email: '',
        ...createPasswordValidation({ requireConfirmation: true })
    };
}

export function createPassword() {
    return {
        ...createPasswordValidation({ requireConfirmation: false })
    };
}

export function changePassword() {
    return {
        current_password: '',
        ...createPasswordValidation({ requireConfirmation: true })
    };
}
