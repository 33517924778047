export default function userAdministration(apiToken) {
    return {
        isModalOpen: false,
        isDeactivateModalOpen: false,
        selectedUser: null,
        formData: {
            username: '',
            email: '',
            password: '',
            department: [],
            role: ''
        },
        passwordRules: getInitialRules(),
        formValid: false,
        errors: {},
        updateFormValid() {
            if (!this.selectedUser) {
                const passwordValid = this.formData.password
                    ? this.passwordRules.every(rule => rule.valid)
                    : false;
                this.formValid = passwordValid &&
                    this.formData.username &&
                    this.formData.email &&
                    this.formData.role;
            } else {
                this.formValid = this.formData.username &&
                    this.formData.email &&
                    this.formData.role;
            }
        },
        watchPassword() {
            this.$watch('formData.password', (value) => {
                this.validatePassword();
            });
        },
        validatePassword() {
            if (!this.selectedUser && this.formData.password) {
                this.passwordRules = validatePasswordRules(this.formData.password);
                this.updateFormValid();
                return this.passwordRules.every(rule => rule.valid);
            }
            if (!this.selectedUser && !this.formData.password) {
                this.passwordRules.forEach(rule => rule.valid = false);
                this.updateFormValid();
                return false;
            }
            return true;
        },
        init() {
            // for per_page select - evnt listener
            document.getElementById('per_page').addEventListener('change', () => {
                document.getElementById('filters-form').submit();
            });
            this.watchPassword();
        },
        openCreateModal() {
            this.errors = {};
            this.selectedUser = null;
            this.formData = {
                username: '',
                email: '',
                password: '',
                department: [],
                role: '',
                active: true
            };
            this.passwordRules.forEach(rule => rule.valid = false);
            this.formValid = false;
            this.isModalOpen = true;
        },

        openEditModal(user) {
            this.errors = {};
            this.selectedUser = user;
            this.formData = {
                username: user.username,
                email: user.email,
                department: user.departments.map(d => d.id),
                role: user.roles[0]?.id || '',
                active: user.active
            };
            this.isModalOpen = true;
        },

        openDeactivateModal(userData) {
            const user = typeof userData === 'string' ? JSON.parse(userData) : userData;
            this.selectedUser = user;
            this.isDeactivateModalOpen = true;
        },

        closeModal() {
            this.isModalOpen = false;
            this.selectedUser = null;
            this.formData = {};
        },

        closeDeactivateModal() {
            this.isDeactivateModalOpen = false;
            this.selectedUser = null;
        },
        async deleteUser(userId) {
            const url = `/api/v1/users/${userId}/delete`;

            if (!window.confirm('This user will be deactivated. Their items will remain allocated.')) {
                return;
            }

            try {
                const response = await fetch(url, {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify(this.formData)
                });

                const contentType = response.headers.get("content-type");

                let responseData;

                if (contentType && contentType.includes("application/json")) {
                    responseData = await response.json();

                    if (!response.ok) {
                        console.log("Error:", responseData.errors || responseData);
                        return;
                    }
                } else {
                    const textData = await response.text();
                    console.log("Response is not JSON:", textData);
                    return;
                }

                window.location.reload();
            } catch (error) {
                console.error("Error on user deactivate:", error);
            }
        },
        async reactivateUser(userId) {
            if (!window.confirm('This user will be reactivated and will appear as active in the system.')) {
                return;
            }

            try {
                const url = `/api/v1/users/${userId}/update`;
                const response = await fetch(url, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify({ active: true })
                });

                const responseData = await response.json();

                if (!response.ok) {
                    console.log("Error:", responseData.errors || responseData);
                    return;
                }

                window.location.reload();
            } catch (error) {
                console.error("Error on user reactivate:", error);
            }
        },
        async handleSubmit() {

            if (!this.selectedUser) {
                const isPasswordValid = this.validatePassword();
                if (!isPasswordValid) {
                    const failedRules = this.passwordRules
                        .filter(rule => !rule.valid)
                        .map(rule => rule.message);

                    this.errors.password = [`Invalid password. Requirements: ${failedRules.join(', ')}`];
                    return;
                }
            }

            try {
                const url = this.selectedUser
                    ? `/api/v1/users/${this.selectedUser.id}/update`
                    : '/api/v1/users';

                const response = await fetch(url, {
                    method: this.selectedUser ? 'PUT' : 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${apiToken}`
                    },
                    body: JSON.stringify(this.formData)
                });

                const responseData = await response.json();

                if (!response.ok) {
                    if (responseData.errors) {
                        this.errors = responseData.errors;
                    } else {
                        this.errors = { general: [responseData.message || 'An error occurred'] };
                    }
                    return;
                }

                window.location.reload();
            } catch (error) {
                console.error('Full error:', error);
                this.errors = { general: ['An error occurred while saving'] };
            }
        }
    }
}
