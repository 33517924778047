export default function multiSelect(fieldName, selected, items, labelField, placeholder) {

    const parseIfString = (value) => {
        try {
            return typeof value === 'string' ? JSON.parse(value) : value;
        } catch (e) {
            return value;
        }
    };

    const itemsCurrent = parseIfString(items);
    const itemsSelected = parseIfString(selected);

    return {
        openSelect: false,
        search: '',
        selectedItems: Array.isArray(itemsSelected) && itemsSelected[0] !== 'all' ? itemsSelected : [],
        items: itemsCurrent,
        fieldName: fieldName,
        labelField: labelField,
        placeholder: placeholder,

        get filteredItems() {
            return this.search.length >= 2
                ? this.items.filter(item =>
                    item[this.labelField].toLowerCase().includes(this.search.toLowerCase()) &&
                    !this.selectedItems.includes(item.id.toString())
                )
                : this.items.filter(item =>
                    !this.selectedItems.includes(item.id.toString())
                );
        },

        init() {
            this.updateHiddenInputs();
            this.$watch('selectedItems', () => this.updateHiddenInputs());
        },

        selectItem(itemId) {
            this.selectedItems.push(itemId.toString());
            this.search = '';
            this.openSelect = false;
        },

        removeItem(itemId) {
            this.selectedItems = this.selectedItems.filter(id => id !== itemId);
        },

        updateHiddenInputs() {
            const container = document.getElementById(`hidden-${this.fieldName}`);
            if (!container) return;

            container.innerHTML = '';

            if (this.selectedItems.length === 0) {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = `${this.fieldName}[]`;
                input.value = 'all';
                container.appendChild(input);
            } else {
                this.selectedItems.forEach(itemId => {
                    const input = document.createElement('input');
                    input.type = 'hidden';
                    input.name = `${this.fieldName}[]`;
                    input.value = itemId;
                    container.appendChild(input);
                });
            }
        }
    };
}
